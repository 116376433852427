import React, { useEffect, useState } from "react";

import { getWeek } from "@/components/pages/weeklyOffers/Offers";
import { ScrollContainer } from "@/components/Scroll/Container";
import { ProvideSliderProduct } from "@/components/Slider/Product/components/Types/WeeklyOfferProducts";
import { medusaClient } from "@/lib/config";

export interface ThemeProductInterface {
  totals: number;
  ids: string[];
}

export const requestThemeProductIds = (theme: string, week: string) => {
  return medusaClient.client.request("GET", `/store/themeworld/${week}/${theme}`);
};

export const ThemeWorld = ({ type }: { type: string }) => {
  const [productIds, setProductIds] = useState<ThemeProductInterface>({ totals: 0, ids: [] });

  useEffect(() => {
    const week = getWeek();

    requestThemeProductIds(type, week).then(({ ids, totals }) => {
      setProductIds({ totals: totals, ids: ids });
    });
  }, [type]);

  return (
    <div className={"relative w-full overflow-y-auto scrollbar-width px-2 md:px-0 md:pr-2 py-2 2xl:py-0"}>
      <ScrollContainer>
        <ProvideSliderProduct ids={productIds.ids} />
      </ScrollContainer>
    </div>
  );
};
